<template>
  <div class="team-management" ref="teamManagement">
    <div class="function-buttons" ref="functionButtons">
      <el-button
        type="primary"
        size="small"
        @click="onAddTeam"
        v-if="nowMenuList.indexOf('新增') >= 0"
        >新增</el-button
      >
    </div>
    <div class="form-area" ref="form">
      <el-form
        :inline="true"
        :model="form"
        class="demo-form-inline"
        label-position="left"
        label-width="82px"
      >
        <el-form-item label="选择线路：">
          <el-select
            v-model="form.companyIds"
            clearable
            multiple
            collapse-tags
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="item in busLineList"
              :key="item.companyId"
              :label="item.companyName"
              :value="item.companyId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="班组名：">
          <el-input v-model="form.keyWord"></el-input>
        </el-form-item>
        <el-form-item label="驾驶员：" label-width="102px">
          <el-select
            v-model="form.credentialsNo"
            filterable
            remote
            reserve-keyword
            clearable
            placeholder="请选择"
            :remote-method="remoteMethod"
            :loading="loading"
          >
            <el-option
              v-for="item in driverList"
              :key="item.credentialsNo"
              :label="item.driverName"
              :value="item.credentialsNo"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label-width="0">
          <el-button
            type="primary"
            size="small"
            @click="onSearch"
            :loading="searchLoading"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="tableData" stripe :height="tableHeight">
      <el-table-column label="序号" type="index" width="60"></el-table-column>
      <el-table-column prop="name" label="班组名称"></el-table-column>
      <el-table-column prop="companyName" label="所属线路"></el-table-column>
      <el-table-column
        label="非机动车司机"
        width="300"
        :formatter="formatNormal"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        label="机动车司机"
        width="300"
        :formatter="formatDynamic"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column prop label="倒班模式">
        <template slot-scope="scope">{{
          scope.row.workShifts | filterModel
        }}</template>
      </el-table-column>
      <el-table-column label="操作" width="140">
        <template slot-scope="scope">
          <el-button
            @click="onUpdateTeam(scope.row)"
            type="text"
            size="small"
            sort="primary"
            v-if="nowMenuList.indexOf('编辑') >= 0"
            >编辑</el-button
          >
          <el-button
            type="text"
            size="small"
            sort="danger"
            @click="onDeleteTeam(scope.row)"
            v-if="nowMenuList.indexOf('删除') >= 0"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination" ref="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @current-change="onCurrentChange"
        @size-change="onSizeChange"
      ></el-pagination>
    </div>

    <!-- 新增编辑 -->
    <el-dialog
      :title="title"
      v-if="dialogVisible"
      :visible.sync="dialogVisible"
      @close="onDialogClose"
      width="30%"
    >
      <addTeam
        ref="addTeam"
        :rowData="rowData"
        @close="onDialogClose"
        @onLoading="onLoading"
      />
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="dialogVisible = false"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="small"
          :loading="editLoading"
          @click="onSave"
          >保存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>

import addTeam from './components/addTeam'
import { mapGetters } from 'vuex'

import {
  getTeamList,
  deleteTeam,
  queryAllBusLineList
} from '@/api/lib/bus-api.js'

import { queryDriverByName } from '@/api/lib/api'

export default {
  name: 'teamManagement',
  components: {
    addTeam
  },
  data () {
    return {
      tableHeight: 0,
      tableData: [],
      form: {
        companyIds: null,
        keyWord: null,
        credentialsNo: null,
        currentPage: 1,
        pageSize: 10
      },
      total: 0,
      title: '',
      rowData: null,
      busLineList: [],
      dialogVisible: false,
      searchLoading: false,
      editLoading: false,
      loading: false,
      driverList: [],
    }
  },
  computed: {
    ...mapGetters({
      nowMenuList: 'nowMenuList'
    }),

  },
  filters: {
    filterModel (item) {
      if (item == 0) {
        return "顺时针";
      } else if (item == 1) {
        return "逆时针";
      } else if (item == 2) {
        return "固定";
      }
    }
  },
  methods: {
    // 计算表格高度
    computeHeight () {
      const wholeHeight = this.$refs.teamManagement.clientHeight
      const buttonsHeight = this.$refs.functionButtons.clientHeight
      const formHeight = this.$refs.form.clientHeight
      const paginationHeight = this.$refs.pagination.clientHeight
      this.tableHeight =
        wholeHeight -
        16 -
        buttonsHeight -
        16 -
        formHeight -
        paginationHeight -
        10 -
        16
    },

    onSearch () {
      this.searchLoading = true
      this.form.currentPage = 1
      this.form.pageSize = 10
      this.getListByField()
    },
    getListByField () {
      let data = { ...this.form }
      getTeamList(data)
        .then((res) => {
          if (res.code === 1000) {
            this.tableData =
              res.data.list && res.data.list.length > 0
                ? res.data.list
                : []
            this.total = res.data.total

          } else {
            this.tableData = []
            this.total = 0
            this.$message.error(res.msg)
          }
          this.searchLoading = false
          this.exportForm = { ...data }
        })
        .catch(() => {
          this.searchLoading = false
        })
    },
    // 当前页码改变
    onCurrentChange (page) {
      this.form.currentPage = page
      this.getListByField()
    },
    // 当前条数改变
    onSizeChange (size) {
      this.form.pageSize = size
      this.form.currentPage = 1
      this.getListByField()
    },
    // 模糊搜索
    remoteMethod (query) {
      if (query !== '') {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          queryDriverByName({ driverName: query }).then((res) => {
            if (res.code === 1000) {
              this.driverList = res.data
            }
          })
        }, 200)
      } else {
        this.driverList = []
      }
    },

    // 新增
    onAddTeam () {
      this.title = '新增班组'
      this.rowData = null
      this.dialogVisible = true
    },
    // 编辑
    onUpdateTeam (row) {
      this.title = '修改班组'
      this.rowData = row
      this.dialogVisible = true
    },
    //  删除
    onDeleteTeam (row) {
      const h = this.$createElement
      this.$msgbox({
        title: '消息',
        type: 'warning',
        message: h('p', null, [
          h('span', null, '您将删除班组 '),
          h('span', { style: 'color: #ed6a0c' }, row.name),
          h('span', null, '  ，是否删除？')
        ]),
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          deleteTeam(row.id).then((res) => {
            if (res.code === 1000) {
              this.$message.success('删除成功')
              this.onSearch()
            } else {
              this.$message.error(res.msg)
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            showClose: true,
            message: '已取消删除'
          })
        })
    },

    // 关闭弹窗
    onDialogClose (flag) {
      this.dialogVisible = false
      if (flag) {
        if (this.title.indexOf('新增') > -1) {
          this.onSearch()
        } else {
          this.getListByField()
        }
      }
      this.editLoading = false
    },
    //  保存
    onSave () {
      this.$refs.addTeam.onSave()
    },
    onLoading (flag) {
      this.editLoading = flag
    },
    //非机动车司机
    formatNormal (row) {
      let driver = ''
      row.teamDriverList.map(item => {
        if (item.driverType == 0) driver += `${item.driverName},`
      })
      return driver.lastIndexOf(",") > -1 ? driver.substr(0, driver.length - 1) : driver
    },
    //机动车司机
    formatDynamic (row) {
      let driver = ''
      row.teamDriverList.map(item => {
        if (item.driverType == 1) driver += `${item.driverName},`
      })
      return driver.lastIndexOf(",") > -1 ? driver.substr(0, driver.length - 1) : driver
    },

    //获取所有线路
    queryAllBusLine () {
      queryAllBusLineList().then((res) => {
        if (res.code === 1000) {
          if (res.data.length == 0) {
            this.$message.warning("还没有线路信息，请先添加线路信息");
          } else {
            this.busLineList = res.data
          }
        }
      })
    }
  },
  created () {
    this.queryAllBusLine()
  },
  mounted () {
    this.$nextTick(() => {
      this.computeHeight()
      this.onSearch()
      window.addEventListener('resize', this.computeHeight, false)
    })
  },
  destroyed () {
    window.removeEventListener('resize', this.computeHeight)
  }
}
</script>

<style lang="scss" scoped>
.team-management {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  box-sizing: border-box;
  @include themify() {
  }
}

.theme-project-bus {
  .team-management {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
</style>
