<template>
  <div class="teamAdd">
    <div class="form search-area">
      <el-form
        :model="form"
        status-icon
        :rules="rules"
        label-position="left"
        ref="form"
        label-width="102px"
        class="demo-form dialog-form"
      >
        <!-- 所属线路 -->
        <el-form-item label="所属线路:" prop="companyId">
          <el-select
            v-model="form.companyId"
            placeholder="请选择线路"
            filterable
            clearable
            :disabled="isEdit"
            @change="getDriverByWorkType"
          >
            <el-option
              v-for="item in busLineList"
              :key="item.companyId"
              :label="item.companyName"
              :value="item.companyId"
            ></el-option>
          </el-select>
        </el-form-item>

        <!-- 班组名称 -->
        <el-form-item label="班组名称:" prop="name">
          <el-input v-model="form.name" placeholder="请填写班组名称"></el-input>
        </el-form-item>

        <!-- 倒班模式 -->
        <el-form-item label="倒班模式:" prop="workShifts">
          <el-select v-model="form.workShifts" placeholder="请选择倒班模式">
            <el-option label="顺时针" value="0"></el-option>
            <el-option label="逆时针" value="1"></el-option>
          </el-select>
        </el-form-item>

        <!-- 机动司机 -->
        <el-form-item label="机动司机:" prop="maneuverIds" style="width: 100%">
          <el-checkbox-group
            v-model="form.maneuverIds"
            @change="changeManeuverIds"
          >
            <el-checkbox
              v-for="item in maneuvers"
              :label="item.credentialsNo"
              :key="item.credentialsNo"
              >{{ item.driverName }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>

        <!-- 非机动司机 -->
        <el-form-item
          label="非机动司机:"
          prop="mainDriverIds"
          style="width: 100%"
        >
          <el-checkbox-group
            v-model="form.mainDriverIds"
            @change="changeMainDriverIds"
          >
            <el-checkbox
              v-for="item in mainDrivers"
              :label="item.credentialsNo"
              :key="item.credentialsNo"
              >{{ item.driverName }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>

        <!-- 轮班顺序 -->
        <el-form-item label="轮班顺序:" prop="colors" style="width: 100%">
          <div class="color-list">
            <div
              class="color-item"
              v-for="color in colors"
              v-dragging="{ item: color, list: colors, group: 'color' }"
              :key="color.credentialsNo"
              :title="color.driverName"
            >
              {{ color.driverName }}
            </div>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import {
  addTeam,
  updateTeam,
  getDriversByLineId,
  queryAllBusLineList,
} from '@/api/lib/bus-api.js'


export default {
  props: {
    rowData: {
      type: Object,
      default: () => { }
    }
  },
  data () {

    return {
      form: {
        companyId: null,
        name: null,
        workShifts: "0",
        maneuvers: [],
        mainDriver: [],
        maneuverIds: [],
        mainDriverIds: []
      },
      busLineList: [],
      isEdit: false,
      rules: {
        companyId: [{ required: true, message: "不能为空", trigger: "blur" }],
        name: [
          { required: true, message: "不能为空", trigger: "blur" },
        ],
        workShifts: [{ required: true, message: "不能为空", trigger: "blur" }],
        mainDriverIds: [
          {
            required: true,
            message: "请选择非机动车司机",
            trigger: "blur",
            type: "array"
          }
        ]
      },
      maneuvers: [],
      mainDrivers: [],
      colors: []
    };
  },



  methods: {
    //选择机动司机
    changeManeuverIds () {
      this.colors = this.colors.filter(item => Number(item.driverType) == 0);
      this.form.maneuverIds.forEach(item => {
        this.maneuvers.forEach(element => {
          if (element.credentialsNo == item) {
            this.colors.push({
              driverName: element.driverName,
              credentialsNo: element.credentialsNo,
              driverType: 1
            });
          }
        });
      });
    },
    //选择非机动车司机(主驾)
    changeMainDriverIds () {
      this.colors = this.colors.filter(item => Number(item.driverType) == 1);
      this.form.mainDriverIds.forEach(item => {
        this.mainDrivers.forEach(element => {
          if (element.credentialsNo == item) {
            this.colors.push({
              driverName: element.driverName,
              credentialsNo: element.credentialsNo,
              driverType: 0
            });
          }
        });
      });
    },

    getDriverByWorkType (lineId) {
      getDriversByLineId({ lineId: lineId }).then(res => {
        if (
          res.data.maneuvers.length === 0 ||
          res.data.mainDrivers.length === 0
        ) {
          if (
            res.data.maneuvers.length === 0 &&
            res.data.mainDrivers.length === 0 &&
            res.data.num > 0
          ) {
            this.$message.warning("所有司机都已编组,无需再编组");
          } else if (
            res.data.maneuvers.length === 0 &&
            res.data.mainDrivers.length === 0 &&
            res.data.num === 0
          ) {
            this.$message.warning("暂无司机可选择，请先添加司机");
          } else if (
            res.data.maneuvers.length !== 0 &&
            res.data.mainDrivers.length === 0
          ) {
            this.$message.warning(
              "非机动司机为绑定车辆司机,请先为司机绑定车辆"
            );
          }
          this.maneuvers = res.data.maneuvers.reverse();
          this.mainDrivers = res.data.mainDrivers.reverse();
          this.form.maneuverIds = [];
          this.form.mainDriverIds = [];
          this.colors = [];
        } else {
          this.maneuvers = res.data.maneuvers.reverse();
          this.mainDrivers = res.data.mainDrivers.reverse();
        }
      });
    },
    getDriverByEdit (lineId) {
      getDriversByLineId({ lineId: lineId }).then(res => {
        if (res.code === 1000) {
          this.maneuvers = this.maneuvers.concat(res.data.maneuvers).reverse();
          this.mainDrivers = this.mainDrivers.concat(res.data.mainDrivers).reverse();
        }
      });
    },


    // 新增/修改
    onSave () {
      let self = this;
      self.$refs.form.validate(valid => {
        if (valid) {
          // 机动车和非机动车参数重组
          var num = 1;
          var teamDrivers = [];
          self.form.maneuvers = [];
          self.form.mainDriver = [];
          this.colors.forEach(item => {
            if (item.driverType === 0) {
              teamDrivers.push({
                credentialsNo: item.credentialsNo,
                driverType: 0,
                order: num++
              });
              self.form.maneuvers.push(item.driverName);
            } else {
              teamDrivers.push({
                credentialsNo: item.credentialsNo,
                driverType: 1,
                order: num++
              });
              self.form.mainDriver.push(item.driverName);
            }
          });
          const data = {
            companyId: self.form.companyId,
            name: self.form.name,
            workShifts: self.form.workShifts,
            maneuvers: self.form.maneuvers.join(),
            mainDriver: self.form.mainDriver.join(),
            teamDrivers: teamDrivers
          };
          if (this.isEdit) {
            data.id = this.rowData.id;
            updateTeam(data).then(res => {
              if (res.code === 1000) {
                this.$message.success('修改成功')
                this.clsoeDia(res.code === 1000)
              } else {
                this.$message.error(res.msg)
              }
              this.$emit('onLoading', false)
            });
          } else {
            addTeam(data).then(res => {
              if (res.code === 1000) {
                this.$message.success('新增成功')
                this.clsoeDia(res.code === 1000)
              } else {
                this.$message.error(res.msg)
              }
              this.$emit('onLoading', false)
            });
          }
        }
      });
    },

    /** 取消 */
    clsoeDia (flag) {
      this.$emit('close', flag)
    },
    // 所有的线路下拉菜单
    queryAllBusLine () {
      queryAllBusLineList().then((res) => {
        if (res.code === 1000) {
          if (res.data.length == 0) {
            this.$message.warning("还没有线路信息，请先添加线路信息");
          } else {
            this.busLineList = res.data
          }
        }
      })
    },
  },
  created () {
    this.queryAllBusLine()
  },
  mounted () {
    let self = this;
    self.$dragging.$on("dragged", value => {
      //因为我这在排序的时候不需要做处理，所以空着就行
    });
    /** 编辑 */
    if (
      JSON.stringify(this.rowData) !== '{}' &&
      JSON.stringify(this.rowData) !== 'null'
    ) {
      self.form = { ...this.rowData }
      self.form.maneuverIds = [];
      self.form.mainDriverIds = [];
      self.colors = [];
      self.form.workShifts = this.rowData.workShifts + "";
      this.getDriverByEdit(this.form.companyId)
      if (this.rowData.teamDriverList.length > 0) {
        //非机动(主驾)
        this.rowData.teamDriverList.forEach(item => {
          delete item.insertTime
          delete item.updateTime
          if (Number(item.driverType) === 0) {
            self.mainDrivers.push(item)
            self.form.mainDriverIds.push(item.credentialsNo);
          } else {
            self.maneuvers.push(item)
            self.form.maneuverIds.push(item.credentialsNo);
          }
        })

      }
      self.colors = [...this.rowData.teamDriverList]
      this.maneuvers = this.maneuvers.reverse();
      this.mainDrivers = this.mainDrivers.reverse();
      delete this.form.insertTime
      delete this.form.updateTime
      delete this.form.shifts
      delete this.form.teamDriverList

      this.isEdit = true
    } else {
      this.isEdit = false

    }
  },

};
</script>

<style lang="scss" scoped>
.teamAdd {
  width: 100%;
  height: 100%;
  th {
    padding-top: 0 !important;
    text-align: center !important;
  }
  .main_con {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90%;
  }
  .bottom {
    flex: 1;
    display: flex;
    .left_con {
      flex: 1;
    }
    .right_con {
      padding-left: 20px;
      flex: 3;
      border: none;
    }
  }
  div.el-select {
    width: 80%;
    div.el-input {
      width: 100%;
    }
  }
  div.el-input {
    width: 80%;
  }
  .addRow {
    position: absolute;
    top: 10px;
  }
  .color-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
  }
  .color-item {
    width: 22%;
    margin-bottom: 0.5vh;
    margin-right: 2%;
    height: 32px;
    line-height: 32px;
    text-align: center;
    border: 1px solid rgb(220, 223, 230);
    border-radius: 4px;
    cursor: pointer;
    background: #ffffff;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

/deep/.el-form-item {
  margin-bottom: 1vh;
}
</style>