var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "teamAdd" }, [
    _c(
      "div",
      { staticClass: "form search-area" },
      [
        _c(
          "el-form",
          {
            ref: "form",
            staticClass: "demo-form dialog-form",
            attrs: {
              model: _vm.form,
              "status-icon": "",
              rules: _vm.rules,
              "label-position": "left",
              "label-width": "102px"
            }
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "所属线路:", prop: "companyId" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      placeholder: "请选择线路",
                      filterable: "",
                      clearable: "",
                      disabled: _vm.isEdit
                    },
                    on: { change: _vm.getDriverByWorkType },
                    model: {
                      value: _vm.form.companyId,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "companyId", $$v)
                      },
                      expression: "form.companyId"
                    }
                  },
                  _vm._l(_vm.busLineList, function(item) {
                    return _c("el-option", {
                      key: item.companyId,
                      attrs: { label: item.companyName, value: item.companyId }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "班组名称:", prop: "name" } },
              [
                _c("el-input", {
                  attrs: { placeholder: "请填写班组名称" },
                  model: {
                    value: _vm.form.name,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "name", $$v)
                    },
                    expression: "form.name"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "倒班模式:", prop: "workShifts" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: { placeholder: "请选择倒班模式" },
                    model: {
                      value: _vm.form.workShifts,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "workShifts", $$v)
                      },
                      expression: "form.workShifts"
                    }
                  },
                  [
                    _c("el-option", { attrs: { label: "顺时针", value: "0" } }),
                    _c("el-option", { attrs: { label: "逆时针", value: "1" } })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                staticStyle: { width: "100%" },
                attrs: { label: "机动司机:", prop: "maneuverIds" }
              },
              [
                _c(
                  "el-checkbox-group",
                  {
                    on: { change: _vm.changeManeuverIds },
                    model: {
                      value: _vm.form.maneuverIds,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "maneuverIds", $$v)
                      },
                      expression: "form.maneuverIds"
                    }
                  },
                  _vm._l(_vm.maneuvers, function(item) {
                    return _c(
                      "el-checkbox",
                      {
                        key: item.credentialsNo,
                        attrs: { label: item.credentialsNo }
                      },
                      [_vm._v(_vm._s(item.driverName))]
                    )
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                staticStyle: { width: "100%" },
                attrs: { label: "非机动司机:", prop: "mainDriverIds" }
              },
              [
                _c(
                  "el-checkbox-group",
                  {
                    on: { change: _vm.changeMainDriverIds },
                    model: {
                      value: _vm.form.mainDriverIds,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "mainDriverIds", $$v)
                      },
                      expression: "form.mainDriverIds"
                    }
                  },
                  _vm._l(_vm.mainDrivers, function(item) {
                    return _c(
                      "el-checkbox",
                      {
                        key: item.credentialsNo,
                        attrs: { label: item.credentialsNo }
                      },
                      [_vm._v(_vm._s(item.driverName))]
                    )
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                staticStyle: { width: "100%" },
                attrs: { label: "轮班顺序:", prop: "colors" }
              },
              [
                _c(
                  "div",
                  { staticClass: "color-list" },
                  _vm._l(_vm.colors, function(color) {
                    return _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "dragging",
                            rawName: "v-dragging",
                            value: {
                              item: color,
                              list: _vm.colors,
                              group: "color"
                            },
                            expression:
                              "{ item: color, list: colors, group: 'color' }"
                          }
                        ],
                        key: color.credentialsNo,
                        staticClass: "color-item",
                        attrs: { title: color.driverName }
                      },
                      [_vm._v(" " + _vm._s(color.driverName) + " ")]
                    )
                  }),
                  0
                )
              ]
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }